<template>
  <div ref="body" @click="closeSelectList">
    <custom-header active="global" :subActive="current"></custom-header>
    
    <div class="content-container">
      
      <!-- 参观团预登记 -->
      <div ref="group" class="group-container">
        <h1>Group Registration</h1>
        <div class="section">
          <div class="content">
            Shenzhen Creative Week provides services tailor-made itineraries for trade groups. To avoid unnecessary delays or service unavailability by requesting these services on site, please pre-register before March 5, 2025, to allow time to customize your visit and make sure your group enjoys all benefits when visiting. Shenzhen Creative Week recommends trade associations and large enterprises consisting of 5 or more people to register as a group.
          </div>
        </div>
        <div class="section">
          <div class="title">Group Benefits</div>
          <ul class="ul1">
            <li>One-on-one reception and convenient access.</li>
            <li>Group photos taken by professional photographers.</li>
            <li>Free shuttle bus is arranged for those who travel within 50 kilometers and have 5 or more people.</li>
            <li>E-shuttles are available for fixed-point pickup and drop-off in the halls.</li>
            <li>Upgraded statues as an overseas VIP visitor and able to enjoy exclusive VIP services.</li>
            <li>Exclusive brand guide service.</li>
          </ul>
        </div>
        <div class="section">
          <div class="title">Group Application Terms:</div>
          <ul class="ul1">
            <li>The members of the visiting group must have clear purchase and cooperation premises.</li>
            <li>A team leader is needed as a unified contact person.</li>
            <li>Overseas buyers purchase in groups: more than 5 people.</li>
          </ul>
        </div>
        <div class="section">
          <div class="title">Contact us:</div>
          <div class="content">
            <a href="mailto:luoliting@szfa.com">luoliting@szfa.com</a>
          </div>
        </div>
        
        <div class="section">
          
          <div ref="request" class="content-container-form ">
            <h2>Submit Visiting  Request</h2>
            <div class="tips text-red">* Mandatory fields</div>
            
            <!-- Visiting -->
            <div class="form-section">
              <div class="title">
                <span>Visiting Request (Multiple selections allowed)</span>
                <span class="text-red">*</span>
              </div>
              <div class="" :class="[errorTip.request || errorTip.others ? 'border-red' : 'border-white']" style="padding-left: 5px;">
                <el-checkbox-group v-model="request" @change="onChange">
                  <el-checkbox label="Furniture">Furniture</el-checkbox>
                  <el-checkbox label="Decor">Decor</el-checkbox>
                  <el-checkbox label="Smart">Smart</el-checkbox>
                  <el-checkbox label="Material">Material</el-checkbox>
                  <el-checkbox label="Cooperation">Cooperation</el-checkbox>
                  <el-checkbox label="Others">Others</el-checkbox>
                </el-checkbox-group>
                <div v-if="isShowOther" class="other-box">
                  <input type="text" v-model.trim="other" class="other-input" @input="onChange1" @blur="onChange1">
                </div>
              </div>
              <span v-if="errorTip.request" class="error-tips text-red">Please select</span>
              <span v-if="errorTip.others" class="error-tips text-red">Please input</span>
            </div>
            
            <!-- Visiting Purpose -->
            <div class="form-section">
              <div class="title">Visiting Purpose</div>
              <div class="sub-title">
                <span>The nature of this visiting request</span>
                <span class="text-red">*</span>
              </div>
              <div class="">
                <textarea v-model="form.purpose" class="textarea" :class="[errorTip.purpose ? 'border-red' : '']" @focus="validErr('purpose')" @blur="validErr('purpose')" style="padding-left: 5px;"></textarea>
                <span v-if="errorTip.purpose" class="error-tips text-red">Please input</span>
              </div>
            </div>
            
            <!-- contact Info -->
            <div class="form-section">
              <div class="title">My contact Info</div>
              
              <!-- 称呼、姓名 -->
              <!-- <div class="row">
                <div class="salutation ">
                  <div class="option relative">
                    <input type="text" v-model="form.sex" readonly placeholder="" class="input sex-input hand" @focus="validErr('sex')" @blur="validErr('sex')" @click.stop="showSexList" :class="[errorTip.sex ? 'border-red' : '']">
                    <span class="trun-icon absolute hand" @click.stop="showSexList">&gt;</span>
                    <div class="label absolute">Salutation <span class="text-red">*</span></div>
                    <div v-if="isShowSexList" class="sex-list absolute">
                      <div class="sex-option"> -- Please select --</div>
                      <div class="sex-option hover" @click.stop="sexChange('MR')">MR</div>
                      <div class="sex-option hover" @click.stop="sexChange('MS')">MS</div>
                    </div>
                  </div>
                  <span v-if="errorTip.sex" class="error-tips text-red">Please select</span>
                </div>
                <div class="name option relative">
                  <div class="option">
                    <input type="text" v-model.trim="form.name" class="input" @focus="validErr('name')" @blur="validErr('name')" :class="[errorTip.name ? 'border-red' : '']">
                    <div class="label absolute">Name <span class="text-red">*</span></div>
                  </div>
                  <span v-if="errorTip.name" class="error-tips text-red">Please input</span>
                </div>
              </div> -->
              
              <!-- 公司名称 -->
              <div class="row">
                <div class="company-name option relative">
                  <div class="option">
                    <input type="text" v-model.trim="form.companyName" class="input" @focus="validErr('companyName')" @blur="validErr('companyName')" :class="[errorTip.companyName ? 'border-red' : '']">
                    <div class="label absolute">Company Name <span class="text-red">*</span></div>
                  </div>
                  <span v-if="errorTip.companyName" class="error-tips text-red">Please input</span>
                </div>
              </div>
              <div class="row">
                <div class="company-name option relative">
                  <div class="option">
                    <input type="text" v-model.trim="form.website" class="input">
                    <div class="label absolute">Website</div>
                  </div>
                </div>
              </div>
              
              <!-- 国家 -->
              <div class="row">
                <div class="country-name option relative">
                  <div class="option">
                    <input type="text" v-model="form.country" readonly placeholder="" class="input hand" @click.stop="showCountryList" @focus="validErr('country')" @blur="validErr('country')" :class="[errorTip.country ? 'border-red' : '']">
                    <span class="trun-icon absolute hand" @click.stop="showCountryList">&gt;</span>
                    <div class="label absolute">Country / Region <span class="text-red">*</span></div>
                    <div v-if="isShowCountryList" class="country-list-box absolute top" :class="[isTop ? 'top' : 'down']">
                      <input type="text" v-model="keyword" class="search-input" @input="inputSearch" @click.stop>
                      <div class="country-list" @click="chooseLocation">
                        <div v-for="country in locationOptions" :key="country.id" :data-name="country.area_name" :data-code="country.national_code" class="country-option">{{country.area_name}}</div>
                      </div>
                    </div>
                  </div>
                  <span v-if="errorTip.country" class="error-tips text-red">Please select</span>
                </div>
                <div class="country-code relative">
                  <div class="option">
                    <input type="text" v-model.trim="form.countryCode" readonly placeholder="" class="input" @focus="validErr('countryCode')" @blur="validErr('countryCode')" :class="[errorTip.countryCode ? 'border-red' : '']">
                    <div class="label absolute">Country / Region Code <span class="text-red">*</span></div>
                  </div>
                  <span v-if="errorTip.countryCode" class="error-tips text-red">Please input</span>
                </div>
              </div>
              
              <!-- 人员列表 -->
              <div class="row row1">
                <template v-for="(item, idx) in userList">
                  <div :key="idx" class="person-container">
                    <div class="person option relative">
                      <input type="text" v-model.trim="item.name" class="input left" @blur="validFormErr" >
                      <div class="label absolute">Name <span class="text-red">*</span></div>
                    </div>
                    <div class="person option relative">
                      <input type="text" v-model.trim="item.department" class="input center" @blur="validFormErr">
                      <div class="label absolute">Department <span class="text-red">*</span></div>
                    </div>
                    <div class="person option relative">
                      <input type="text" v-model.trim="item.position" class="input center" @blur="validFormErr">
                      <div class="label absolute">Duties <span class="text-red">*</span></div>
                    </div>
                    <div class="person option relative">
                      <div class="input input-box flex align-center">
                        <span v-if="form.countryCode" class="phone-code">+ {{form.countryCode}}</span>
                        <input type="text" v-model.trim="item.phone" placeholder="" class="input-1 center" @blur="validFormErr" @input="item.phone = item.phone.replace(/[^\d]/g,'')">
                      </div>
                      <div class="label absolute">Phone <span class="text-red">*</span></div>
                    </div>
                    <div class="person option relative">
                      <input type="text" v-model.trim="item.mailbox" placeholder="" class="input center" @blur="validFormErr">
                      <div class="label absolute">Email Address <span class="text-red">*</span></div>
                    </div>
                    <div class="person option relative">
                      <input type="text" v-model.trim="item.idCard" placeholder="" class="input" @blur="validFormErr">
                      <div class="label absolute">Passport No. <span class="text-red">*</span></div>
                    </div>
                  </div>
                </template>
                <div v-if="errorTip.form" class="error-tips text-red">Please input</div>
                <button class="add-btn" @click="addPerson">Add</button>
              </div>
            </div>
            
            <div class="form-section">
              <div class="agree-box flex">
                <div class="checked">
                  <el-checkbox v-model="isChecked"></el-checkbox>
                </div>
                <div class="agree-text" @click="toSwitchAgree">I agree to share my contact information with the relevant suppliers for business matching.</div>
              </div>
              <div v-if="!isChecked" class="error-tips text-red">Please select</div>
            </div>
            
            <button class="submit-btn" @click="submit">{{ time === 0 ? 'Submit' : (time + ' S')}}</button>
          </div>
          
        </div>

      </div>

    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
    <a href="#group" id="to-group" class="hide">aaaa</a>
    
  </div>
</template>

<script>
import { Toast } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import countryList from '../utils/countrys.js';
import { procureGroup } from '@/api/index.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'SZ Creative Week - Group Registration, Benefits, Application T&Cs', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: "SZ Creative Week Provides Services Tailor-Made Itineraries for Trade Groups. Read about Group Benefits, Application T&C's, and Submit Visiting Request Now."},
    ],
  },
  data() {
    return {
      current: 'register',
      isShow: false, // 是否显示国家地区列表
      countryList: [],
      locationOptions: [], // 国家列表
      isShowOther: false,
      isShowSexList: false,
      isShowCountryList: false,
      isChecked: true,
      request: [],
      userList: [
        {
          name: '',
          department: '',
          position: '',
          phone: '',
          mailbox: '',
          idCard: '',
        },
      ],
      form: {
        purpose: '', // 采购目的
        // sex: '',
        // name: '',
        companyName: '',
        country: '',
        countryCode: '',
        website: '',
      },
      other: '',
      errorTip: {
        request: false,
        others: false,
        purpose: false,
        sex: false,
        companyName: false,
        country: false,
        countryCode: false,
        form: false,
      },
      location: '',
      limitCode: 'A45043-513E49',
      time: 0,
      timer: null,
    }
  },
  created() {
    if (this.$route.query.l) {
      this.limitCode = this.$route.query.l;
    }
    // /register?k=group
    this.location = this.$route.query.k || '',
    this.getCountrys();
  },
  mounted() {
    if (this.location === 'group') {
      document.getElementById("to-group").click();
    }
  },
  methods: {
    addPerson() {
      this.userList.push({
        name: '',
        department: '',
        position: '',
        phone: '',
        mailbox: '',
        idCard: '',
      })
    },
    getCountrys() {
      this.countryList = Object.freeze(countryList);
      this.locationOptions = countryList;
    },
    showSexList() {
      this.isShowCountryList = false;
      this.isShowSexList = true;
    },
    sexChange(sex) {
      this.form.sex = sex;
      this.isShowSexList = false;
      this.errorTip.sex = false;
    },
    closeSelectList() {
      this.isShowSexList = false;
      this.isShowCountryList = false;
    },
    onChange(vals) {
      console.log(vals);
      if (vals.includes('Others')) {
        this.isShowOther = true;
        this.errorTip.others = this.other == '';
      } else {
        this.isShowOther = false;
        this.errorTip.others = false;
        this.other = '';
      }
      this.errorTip.request = vals.length == 0;
      
    },
    onChange1(vals) {
      this.errorTip.others = this.other == '';
    },
    validErr(key) {
      this.errorTip[key] = !this.form[key];
    },
    validFormErr() {
      this.clearEmptyRow(this.userList);
      if (this.userList.length === 0) {
        this.addPerson();
      }
      let list = this.userList;
      for (let i = 0; i < list.length; i++) {
        let e = list[i];
        if (e.name === '' || e.department === '' || e.position === '' || e.phone === '' || e.mailbox === '' || e.idCard === '') {
          this.errorTip.form = true;
          break;
        }
        this.errorTip.form = false;
      }
    },
    // 清空空白行
    clearEmptyRow(list) {
      for (let i = 0; i < list.length; i++) {
        let e = list[i];
        if (e.name === '' && e.department === '' && e.position === '' && e.phone === '' && e.mailbox === '' && e.idCard === '') {
          list.splice(i, 1);
          this.clearEmptyRow(list)
        }
      }
    },
    // 显示国家地区列表
    showCountryList() {
      if (document.documentElement.scrollTop > 1500) {
        this.isTop = false;
      } else{
        this.isTop = true;
      }
      this.isShowSexList = false;
      this.isShowCountryList = true;
      this.keyword = '';
      this.locationOptions = this.countryList;
    },
    toSwitchAgree() {
      this.isChecked = !this.isChecked;
    },
    // 输入搜索(字母搜索、区号搜索)
    inputSearch() {
      this.locationOptions = this.keyword ? this.countryList.filter( e => e.area_name.toLowerCase().includes(this.keyword.toLowerCase()) || e.national_code == this.keyword) : this.countryList;
    },
    // 国家地区列表选择事件
    chooseLocation(e) {
      // let {code, name} = e.currentTarget.dataset;
      let {code, name} = e.target.dataset;
    
      this.form.countryCode = code; // 电话区号
      this.form.country = name; // 国家名称
      this.isShowCountryList = false;
      this.errorTip.country = false;
      this.errorTip.countryCode = false;
    },
    submit() {
      if (this.time > 0) return;
      
      if (this.request.length == 0) {
        this.errorTip.request = true;
        this.$refs['request'].scrollIntoView({ behavior: "smooth" });
        return;
      }
      if (this.request.includes('Others') && this.other == '') {
        this.errorTip.others = true;
        return;
      }
    
      // 校验必填项是否存在内容
      for (let [k,v] of Object.entries(this.form)) {
        
        // 非必填项: website
        if (k !== 'website' && !v) {
          this.errorTip[k] = true;
          return;
        }
      }

      this.validFormErr();
      if (this.errorTip.form) return;
      
      if (this.request.includes('Others') && this.other) {
        let idx = this.request.findIndex(e => e === 'Others');
        this.request.splice(idx, 1, 'Others: ' + this.other)
      }
      let data = {
        request: this.request.join(', '),
        ...this.form,
        userList: this.userList,
      };
      
      this.countDown(60);
      procureGroup(data).then(res => {
        if (res.resultStatus) {
          Toast('Submitted successfully');
        } else{
          Toast('Failed to submit');
          this.bufferHandle(); // 缓冲5秒钟
        }
      }).catch(error => {
        Toast(error.message);
        this.bufferHandle(); // 缓冲5秒钟
      })
    
    },
    // 缓冲5秒钟
    bufferHandle() {
      clearInterval(this.timer);
      setTimeout(e => {
        this.countDown(5);
      })
    },
    // 倒计时读秒
    countDown(second) {
      this.time = second;
      this.timer = setInterval(() => {
        this.time -= 1;
        if (this.time == 0) {
          clearInterval(this.timer)
        }
      }, 1000); 
    },
    
  },
}
</script>

<style>
@media screen and (min-width: 1024px) {
  .van-toast {
    padding: 3px; 
    font-size: 6px;
    line-height: 2;
  }
}
</style>
<style scoped lang="less">
  
@purplish-red: #E4007F; // 紫红色
@cyan: #00ABA5; // 紫红色

.text-red {
  color: red;
}

// 移动端
@media screen and (max-width: 1023px) {

  .content-container {
    width: 375px;
    margin: 15px auto;
    
    .img-box {
      background-color: #ededed;
      width: 100%;
      
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    
    .group-container {
      padding: 0 20px;
      margin-bottom: 90px;
      
      h1 {
        font-family: 'Poppins-Medium';
        text-align: center;
        margin-bottom: 24px;
        font-size: 16px;
      }
      .title {
        font-family: 'Poppins-Bold';
        font-size: 14px;
        margin: 5px 0 8px;
      }
      .sub-title {
        font-family: 'Poppins-Medium';
        font-size: 12px;
        margin: 5px 0;
      }
      
      .ul1,
      .ul2 {
        font-size: 12px;
        margin-bottom: 5px;
        
        li {
          margin-bottom: 5px;
        }
      }
      .ul1 {
        list-style: disc;
        list-style-position: inside;
      }
      
      .button {
        font-family: 'Poppins-Medium';
        display: block;
        background-color: @purplish-red;
        color: #fff;
        border: none;
        width: 180px;
        padding: 6px 0;
        margin: 18px auto;
        font-size: 14px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
      
      .section {
        margin-bottom: 20px;
        
        .content {
          font-size: 12px;
          margin-bottom: 15px;
          
          a {
            color: #409eff;
          }
        }
        
      }
      
    }

  }
  
  .content-container-form {
    width: 335px;
    margin: 40px auto 20px;
    
    h2 {
      font-size: 17px;
      text-align: center;
      // font-family: 'Poppins-Bold';
      font-family: 'Poppins-Medium';
    }
    
    .tips {
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
    }
    
    .other-box {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 14px;
      color: #606266;
      
      .other-input {
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        padding: 0 5px;
        box-sizing: border-box;
        width: 100%;
        height: 28px;
        font-size: 12px;
      }
    }
    
    /deep/ .el-checkbox {
      margin-right: 15px;
      margin-bottom: 5px;
    }
    
    .textarea {
      border: .5px solid #DCDFE6;
      padding: 3px 8px;
      margin: 2px 0;
      box-sizing: border-box;
      width: 100%;
      height: 70px;
      font-size: 14px;
      color: #606266;
    }
    
  
    .agree-box {
      align-items: flex-start;
      
      .agree-text {
        font-size: 13px;
        line-height: 1.5;
        cursor: pointer;
      }
    }
    
    .form-section {
      margin-bottom: 18px;
      font-size: 14px;
      
      .title {
        font-size: 13px;
        font-family: 'Poppins-Bold';
        margin-bottom: 5px;
      }
      
      .sub-title {
        font-size: 12px;
        margin: 2px 0;
      }
    }
    
    .row {
      margin: 18px 0;
      
      .trun-icon {
        top: 7px;
        right: 10px;
        z-index: 1;
        display: block;
        width: 16px;
        height: 16px;
        font-size: 16px;
        text-align: center;
        line-height: 16px;
        transform: rotate(90deg);
      }
      
      .input {
        border: 1px solid #DCDFE6;
        padding: 5px 10px 0;
        box-sizing: border-box;
        width: 100%;
        height: 30px;
        font-size: 12.5px;
      }
      
      .input-box {
        padding: 5px 10px 0 10px;
      }
      .input-1 {
        width: calc(100% - 45px);
        height: 20px;
        margin-left: 5px;
        border: none;
        font-size: 12.5px;
      }
      .phone-code {
        display: block;
        width: auto;
        min-width: 40px;
        color: #ccc;
        font-size: 12.5px;
        text-align: right;
      }
      
      .error-tips {
        font-size: 13px;
      }
      
      .option {
        
        .label {
          top: -10px;
          left: 10px;
          z-index: 2;
          cursor: text;
          padding: 0 2px;
          font-size: 13px;
          background-color: #fff;
          color: #999;
          pointer-events: none;
        }
        
      }
    }
    
    .salutation,
    .name,
    .company-name,
    .country-name,
    .person {
      width: 100%;
    }
    
    .person-container {
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      padding: 15px 10px 10px;
      
    }
    .person-container + .person-container {
      margin-top: 20px;
    }
    
    .salutation,
    .country-name,
    .country-code,
    .person {
      margin-bottom: 20px;
    }
    
    .person:last-child {
      margin-bottom: 0;
    }
    
    .salutation {
      
      .sex-list {
        top: 29px;
        left: 0;
        z-index: 5;
        width: 335px;
        height: 80px;
        overflow: hidden;
        padding: 0;
        background-color: #fff;
        border: 1px solid #DCDFE6;
        
        .sex-option {
          font-size: 14px;
          line-height: 1.8;
          padding: 0 5px;
        }
        
      }
    }
    
    .country-list-box {
      width: 335px;
      left: 0;
      z-index: 5;
      background-color: #fff;
      border: 1px solid #DCDFE6;
      box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
      
      &.top {
        top: -248px;
      }
      &.down {
        top: 30px;
      }
      
      .search-input {
        display: block;
        border: 1px solid #DCDFE6;
        padding: 0 8px;
        box-sizing: border-box;
        width: 320px;
        height: 28px;
        font-size: 13px;
        margin: 5px auto;
      }
      
      .country-list {
        width: 100%;
        height: 200px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 5px 10px;
        
        .country-option {
          font-size: 12px;
          line-height: 2;
          padding: 0 2px;
        }
        
      }
      
    }
    
    .border-red {
      border: 0.5px solid red!important;
    }
    .border-white {
      border: 0.5px solid #fff!important;
    }
    
    .add-btn {
      display: block;
      background-color: #fff;
      border: none;
      box-shadow: 0 3px 6px 1px rgba(150, 150, 150, 0.3);
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin: 4px auto;
      cursor: pointer;
      font-size: 12px;
    }
    
    .submit-btn {
      display: block;
      background-color: #409eff;
      border: none;
      color: #fff;
      font-size: 15px;
      border-radius: 4px;
      padding: 5px 30px;
      margin: 30px auto;
      cursor: pointer;
    }
  }
  
}

// PC
@media screen and (min-width: 1024px) {

  .content-container {
    width: 240px;
    margin: 15px auto;
    
    .img-box {
      background-color: #ededed;
      width: 100%;
      
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 1px;
      }
    }

  }
  
  .group-container {
    width: 204px;
    margin: 0 auto 40px;
    
    h1 {
      font-family: 'Poppins-Medium';
      text-align: center;
      margin-bottom: 8px;
      font-size: 6px;
    }
    .title {
      font-family: 'Poppins-Bold';
      font-size: 3.6px;
      margin: 1px 0 2px;
    }
    .sub-title {
      font-family: 'Poppins-Medium';
      font-size: 3px;
      margin: 1px 0;
    }
    
    .ul1,
    .ul2 {
      font-size: 3px;
      margin-bottom: 2px;
      
      li {
        margin-bottom: 1px;
      }
    }
    .ul1 {
      list-style: disc;
      list-style-position: inside;
    }
    
    .button {
      font-family: 'Poppins-Medium';
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 60px;
      padding: 2px 0;
      margin: 6px auto;
      font-size: 5px;
      text-align: center;
      border-radius: 1.2px;
      cursor: pointer;
    }
    
    .section {
      margin-bottom: 5px;
      
      .content {
        font-size: 3px;
        margin-bottom: 3px;
        
        a {
          color: #409eff;
        }
      }
      
    }
    
  }
  
  .content-container-form {
    width: 200px;
    margin: 0 auto 20px;
    padding: 10px 0;
    
    h2 {
      font-size: 5px;
      text-align: center;
      // font-family: 'Poppins-Bold';
      font-family: 'Poppins-Medium';
      margin-bottom: 2px;
    }
    
    .tips {
      font-size: 4px;
      text-align: center;
      margin-bottom: 8px;
    }
    
    .other-box {
      font-size: 4px;
      color: #606266;
      margin-left: 1px;
      
      .other-input {
        border-radius: 1px;
        border: .5px solid #DCDFE6;
        padding: 2px;
        box-sizing: border-box;
        width: 145px;
        height: 10px;
        margin-bottom: 2px;
        font-size: 4px;
      }
    }
    
    .textarea {
      border: .3px solid #DCDFE6;
      padding: 2px;
      margin: 2px 0 0;
      box-sizing: border-box;
      width: 100%;
      height: 35px;
      font-size: 4px;
      color: #606266;
    }
    
    /deep/ .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 3px;
      
      .el-checkbox {
        transform: scale(.7);
        display: flex;
        align-items: center;
        
        font-size: 5px;
        margin: 2px 5px 0 -4px;
        
        .el-checkbox__label {
          padding-left: 5px;
          line-height: 1.1;
          font-size: 4.8px;
        }
        
        .el-checkbox__input {
          &.is-checked .el-checkbox__inner {
            background-color: #409eff;
            border-color: #409eff;
          }
          .el-checkbox__inner {
            border: 0.5px solid #DCDFE6;
            border-radius: 1px;
            width: 7px;
            height: 7px;
  
            &:after {
              height: 4px;
              left: 1.8px;
              top: 0;
              width: 2px;
            }
          }
        }
        
      }
      
    }
    
    .agree-box {
      align-items: flex-start;
      
      .checked {
        transform: scale(.6);
        
        /deep/ .el-checkbox {
          margin: 0;
          padding: 0;
          font-size: 3.8px;;
          
          .el-checkbox__input {
            &.is-checked .el-checkbox__inner {
              background-color: #409eff;
              border-color: #409eff;
            }
            .el-checkbox__inner {
              border: 0.5px solid #DCDFE6;
              border-radius: 1px;
              width: 7px;
              height: 7px;
  
              &:after {
                height: 4px;
                left: 1.6px;
                top: 0;
                width: 2px;
              }
            }
          }
        }
        
      }
      
      .agree-text {
        font-size: 3.8px;;
        margin-left: 3px;
        line-height: 1.8;
        cursor: pointer;
      }
    }
    
    .form-section {
      margin-bottom: 8px;
      font-size: 3.8px;
      
      .title {
        font-size: 3.8px;
        font-family: 'Poppins-Bold';
      }
      
      .sub-title {
        font-size: 3.2px;
        margin: 2px 0;
      }
    }
    .row1,
    .row {
      display: flex;
      margin: 8px 0;
      
      .trun-icon {
        top: 1px;
        right: 2px;
        z-index: 1;
        display: block;
        width: 10px;
        height: 10px;
        font-size: 7px;
        text-align: center;
        line-height: 10px;
        transform: rotate(90deg);
      }
      
      .input {
        border: .3px solid #DCDFE6;
        padding: 2px 5px 0;
        box-sizing: border-box;
        width: 100%;
        height: 12px;
        font-size: 3.6px;
        
        &.left {
          border-right: none;
        }
        &.right {
          border-left: none;
        }
        &.center {
          border-radius: 0;
          border-right: none;
        }
        &.bottom {
          border-top: none;
        }
      }
      
      .input-box {
        padding: 2px 1px 0 1px;
      }
      .input-1 {
        width: calc(100% - 11px);
        height: 8px;
        margin-left: 1px;
        border: none;
        font-size: 3px;
      }
      .phone-code {
        display: block;
        width: auto;
        min-width: 10px;
        color: #ccc;
        font-size: 3px;
        text-align: right;
      }
      
      .error-tips {
        font-size: 3.6px;
      }
      
      .option {
        
        .label {
          top: -2px;
          left: 2px;
          z-index: 2;
          cursor: text;
          padding: 0 2px;
          font-size: 3.4px;
          background-color: #fff;
          color: #999;
          pointer-events: none;
        }
        
      }
    }
    
    .row1 {
      display: block;
    }
    
    .salutation {
      width: 70px;
      
      .sex-list {
        top: 12px;
        left: 0;
        z-index: 5;
        width: 90px;
        height: 25px;
        overflow: hidden;
        padding: 1px 0;
        background-color: #fff;
        border: 0.5px solid #DCDFE6;
        
        .sex-option {
          font-size: 4.5px;
          line-height: 1.6;
          padding: 0 3px;
          
          &.hover:hover {
            cursor: pointer;
            background-color: #409eff;
            color: #fff;
          }
        }
        
      }
    }
    
    .country-list-box {
      width: 200px;
      top: 11.8px;
      left: 0;
      z-index: 5;
      background-color: #fff;
      border: 0.3px solid #DCDFE6;
      
      .search-input {
        border: .3px solid #DCDFE6;
        padding: 2px;
        box-sizing: border-box;
        width: 195px;
        height: 9px;
        font-size: 4px;
        margin: 2px;
        border-radius: 1px;
      }
      
      .country-list {
        width: 100%;
        height: 60px;
        overflow-y: auto;
        padding: 1px 2px;
        
        
        .country-option {
          font-size: 3.6px;
          line-height: 2;
          padding: 0 2px;
          
          &:hover {
            cursor: pointer;
            background-color: #409eff;
            color: #fff;
          }
        }
        
      }
      
    }

    .country-name {
      width: 125px;
    }
    .country-code {
      width: 70px;
      margin-left: 5px;
    }
    
    .company-name {
      width: 100%;
    }
    
    .person-container {
      display: flex;
      margin-bottom: 1px;
    }
    .person-container + .person-container {
      margin-top: 4px;
    }
    
    .person {
      flex: 1;
      // margin-bottom: 4px;
    }
    
    .border-red {
      border: 0.3px solid red!important;
    }
    .border-white {
      border: 0.3px solid #fff!important;
    }
    
    .add-btn {
      display: block;
      background-color: #fff;
      border: none;
      box-shadow: 0 1px 4px 1px rgba(150, 150, 150, 0.3);
      width: 15px;
      height: 15px;
      border-radius: 20px;
      margin: 4px auto;
      cursor: pointer;
    }
    
    .submit-btn {
      display: block;
      background-color: #409eff;
      border: none;
      color: #fff;
      font-size: 5px;
      border-radius: 1px;
      padding: 2px 10px;
      margin: 10px auto;
      cursor: pointer;
    }
  }
  
  
}

</style>